/**
 * 引入网络请求基类
 */
import request from "@/utils/requestUtils";
import { ElMessage } from "element-plus";

/**
 * 取得自定义列表
 * @returns {*}
 */
export const apiCall = function (url, params, localization, methods = "post") {
  if (localization) {
    ElMessage.error("不能使用静态接口地址：" + url);
    return {};

    /*let dataJson = require(`@/api/json${url}.json`);
        console.log(`@/api/json${url}`)
        if (dataJson) {
            console.log(params,dataJson)
            return new Promise((resolve) => {
                resolve(dataJson);
            });
        }*/
  }
  if (methods.toUpperCase() === "GET") {
    return request({ url: url, params: params, method: "get" });
  } else {
    return request({ url: url, data: params, method: "post" });
  }
};

/**
 * 接口列表
 */
export const apiUrlEnums = {
  /**
   * 通用接口
   */
  getAgentRoleMenu: "/agent/getAgentRoleMenu", //取得角色菜单
  agentDepartmentTree: "/agent/agentDepartmentTree", //取得部门树
  agentBankList: "/agent/agentBankList", //银行列表
  getAreaList: "/agent/getAreaList", //区域列表
  getOssSts: "/agent/getOssSts", //获取sts

  /**
   * 用户相关接口
   */
  agentUserLogout: "/agent/agentUserLogout", //用户退出
  agentUserPwdLogin: "/agent/agentUserPwdLogin", //用户密码登录
  agentUserSmsLogin: "/agent/agentUserSmsLogin", //用户短信登录
  agentUserFindPwd: "/agent/agentUserFindPwd", //用户找回密码
  agentUserSendSms: "/sms/send", //用户发送短信
  agentUserPwdUpdate: "/agent/agentUserPwdUpdate", //用户密码修改
  agentUserNumberTotal: "/agent/agentUserNumberTotal", //用户数量统计
  agentUserCategoryTrends: "/agent/agentUserCategoryTrends", //用户类别趋势
  agentUserMemberRatio: "/agent/agentUserMemberRatio", //取得用户会员比例
  agentUserRegPayNumber: "/agent/agentUserRegPayNumber", //用户注册付费
  agentUserPopularizeOrder: "/agent/agentUserPopularizeOrder", //用户推广排名
  agentPopularizeTree: "/agent/agentPopularizeTree", //推广人员树
  agentUserList: "/agent/agentUserList", //取得用户列表
  agentUserByEmployeeIdList: "/agent/agentUserByEmployeeIdList", //取得代理商用户推广用户列表
  agentUserOrderList: "/agent/agentUserOrderList", //用户订单列表
  agentUserInviteList: "/agent/agentUserInviteList", //用户邀请列表
  trackList: "/agent/trackList", // 用户跟进记录列表
  addTrack: "/agent/addTrack", // 新增用户跟进记录
  editTrack: "/agent/editTrack", // 编辑用户跟进记录
  delTrack: "/agent/delTrack", // 删除用户跟进记录
  userBindingList: "/agent/userBindingList", // 绑定记录
  payQrCode: "/agent/payQrCode", // 获取付款码

  // 开通数据
  agentUserJoinVip: "/agent/agentUserJoinVip", // 开通星耀会员
  agentStarFeeList: "/agent/agentStarFeeList", // 星耀会员列表
  agentStarOrderList: "/agent/agentStarOrderList", // 开通数据列表
  agentStarVipRefund: "/agent/agentStarVipRefund", // 退款
  // 预注册
  preRegisterAdd: "/agent/preRegisterAdd", // 新增预注册
  preRegisterList: "/agent/preRegisterList", // 预注册列表
  preRegisterEdit: "/agent/preRegisterEdit", // 编辑预注册
  preRegisterDel: "/agent/preRegisterDel", // 删除预注册
  preRegisterEmployeeList: "/agent/preRegisterEmployeeList", // 负责人列表
  preRegisterImport: 'agent/preRegisterImport', // 保存预注册文件地址
  /**
   * 套餐
   */
  agentUserMealNumberMoney: "/agent/agentUserMealNumberMoney", //套餐数量金额列表
  agentOrderList: "/agent/agentOrderList", //订单列表

  /**
   * 推广
   */
  agentPopularizeArea: "/agent/agentPopularizeArea", //推广区域搜索
  agentPopularizeList: "/agent/agentPopularizeList", //推广列表
  agentPopularizeAdd: "/agent/agentPopularizeAdd", //添加推广申请
  agentPopularizeEdit: "/agent/agentPopularizeEdit", //编辑推广申请
  agentPopularizeLog: "/agent/agentPopularizeLog", //推广记录
  agentPopularizeDel: "/agent/agentPopularizeDel", //申请取消
  agentPopularizeUp: "/agent/agentPopularizeUp", //上传推广货单凭证
  agentPopularizeUserTotal: "/agent/agentPopularizeUserTotal", //推广用户统计
  agentPopularizeUserCategoryTrends: "/agent/agentPopularizeUserCategoryTrends", //推广用户流量趋势
  agentPopularizeUserRegList: "/agent/agentPopularizeUserRegList", //推广数据用户明细
  agentPopularizeUserOrderList: "/agent/agentPopularizeUserOrderList", //推广数据用户明细

  /**
   * 员工
   */
  agentEmployeeList: "/agent/agentEmployeeList", //员工列表
  agentEmployeeAdd: "/agent/agentEmployeeAdd", //代理商员工添加
  agentEmployeeEdit: "/agent/agentEmployeeEdit", //代理商员工编辑
  agentEmployeeTotal: "/agent/agentEmployeeTotal", //员工统计列表
  agentEmployeeTotalAll: "/agent/agentEmployeeTotalAll", //员工统计列表(无数据权限)
  agentEmployeeUserTotal: "/agent/agentEmployeeUserTotal", //员工用户统计
  agentEmployeeUserList: "/agent/agentEmployeeUserList", //员工用户列表
  agentEmployeeUpdate: "/agent/agentEmployeeUpdate", //修改员工信息
  agentEnterpriseEmployeeList: "/agent/agentEnterpriseEmployeeList", //企业员工列表

  /**
   * 部门
   */
  agentDepartmentTotal: "/agent/agentDepartmentTotal", //代理商部门统计
  agentDepartmentDel: "/agent/agentDepartmentDel", //代理商删除部门
  agentDepartmentAdd: "/agent/agentDepartmentAdd", //代理商添加部门
  agentDepartmentEdit: "/agent/agentDepartmentEdit", //代理商编辑部门
  agentEnterpriseEmployeeAdd: "/agent/agentEnterpriseEmployeeAdd", //代理商员工添加
  agentUpdate: "/agent/agentUpdate", //修改代理商信息
  agentInfo: "/agent/agentInfo", //取得代理商信息

  /**
   * 角色
   */
  agentRoleAdd: "/agent/agentRoleAdd", //添加角色
  agentRoleDel: "/agent/agentRoleDel", //删除角色
  agentRoleEdit: "/agent/agentRoleEdit", //添加角色
  agentRoleList: "/agent/agentRoleList", //角色列表
  agentRoleEmployeeList: "/agent/agentRoleEmployeeList", //角色成员列表
  agentRoleEmployeeDel: "/agent/agentRoleEmployeeDel", //移除角色成员
  agentDepartmentEmployeeTree: "/agent/agentDepartmentEmployeeTree", //取得部门成员树
  agentRoleAuthorityList: "/agent/agentRoleAuthorityList", //取得角色权限列表
  agentRoleAuthorityEdit: "/agent/agentRoleAuthorityEdit", //角色权限修改

  /**
   * 提现
   */
  agentWalletTotal: "/agent/agentWalletTotal", //钱包统计信息
  agentWalletWithdrawList: "/agent/agentWalletWithdrawList", //钱包提现明细
  agentWalletProfitList: "/agent/agentWalletProfitList", //钱包收益明细
  agentWalletWithdrawAccountList: "/agent/agentWalletWithdrawAccountList", //取得提现银行列表
  agentWalletWithdrawApplySubmit: "/agent/agentWalletWithdrawApplySubmit", //提现申请提交
  agentWalletWithdrawBankAddSubmit: "/agent/agentWalletWithdrawBankAddSubmit", //添加提现银行卡
  agentWalletWithdrawBankDel: "/agent/agentWalletWithdrawBankDel", //删除提现银行卡
  userPickUpList: "/agent/agentUserPickUpList", //领取记录列表
  userPickUp: "/agent/agentUserPickUp", //员工认领用户
  userRelievePickUp: "/agent/agentUserRelievePickUp", // 用户解绑
  agentUserPickUpSearch: "/agent/agentUserPickUpSearch", //搜索领取用户
  userPickUpSearch: "/agent/agentUserPickUpSearch", //领取查询手机号
};
