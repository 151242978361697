import axios from "axios";
import verifyUtils from "@/utils/verifyUtils";
import router from "@/router/index";
import { ElMessage } from "element-plus"
import baseUtils from "@/utils/baseUtils";


/**
 * 网络请求设置
 */
const service = axios.create({
    method: "post",
    baseURL: baseUtils.getApiUrl(),
    // headers: { 'Accept': 'application/json', 'ContentType': 'application/json', 'Authorization': `bearer ${baseUtils.getToken()}` },
    timeout: 50000,
});

/**
 * 添加请求拦截器
 */
service.interceptors.request.use(
    config => {
        //设置请求数据类型
        // config.params["XDEBUG_SESSION_START"] = "10007";
        config.headers["Accept"] = "application/json";
        config.headers["Content-Type"] = "application/json";
        config.headers["Authorization"] = "bearer " + baseUtils.getToken();
        //设置加密参数
        if (config.params) {
            config.params = verifyUtils.encrypt(config.params);
        }
        //返回值
        return config;
    },
    error => {
        ElMessage.error("调用接口异常：" + error)
        return Promise.reject();
    }
);

/**
 * 添加响应拦截器
 */
service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code === 403) {
                router.push("/login").then();
                return;
            }

            return response.data;
        } else {
            ElMessage.error("网络异常")
            Promise.reject();
        }
    },
    error => {
        ElMessage.error("接口返回异常：" + error)
        return Promise.reject();
    }
);

export default service;
