import {createStore} from 'vuex'

/**
 * 菜单选项卡操作
 */
export default createStore({
    state: {
        tagsList: [],
        headList: ["/admin/home", "/account/home"],
        collapse: false,
    },
    mutations: {
        /**
         * 关闭菜单选项卡
         * @param state
         * @param data
         */
        delTagsItem(state, data) {
            if (state.headList.includes(state.tagsList[data.index].path)) {
                if (state.tagsList.length > 1) {
                    state.tagsList.splice(data.index, 1);
                }
            } else {
                state.tagsList.splice(data.index, 1);
            }
        },

        /**
         * 打开新页面菜单选项卡
         * @param state
         * @param data
         */
        setTagsItem(state, data) {
            if (state.headList.includes(data.path)) {
                state.tagsList.reverse();
                state.tagsList.push(data);
                state.tagsList.reverse();
            } else {
                state.tagsList.push(data);
            }
        },

        /**
         * 清空菜单选项卡
         * @param state
         */
        clearTags(state) {
            state.tagsList = []
        },

        /**
         * 关闭（其他、全部）菜单选项卡
         * @param state
         * @param data
         */
        closeTagsOther(state, data) {
            state.tagsList = data;
        },

        /**
         * 折叠菜单
         * @param state
         * @param data
         */
        collapseChg(state, data) {
            state.collapse = data;
        }
    },
    actions: {},
    modules: {}
})
