<template>
    <div class="tags" v-if="showTags">
        <ul>
            <li class="tags-li" v-for="(item,index) in tagsList" :class="{'active': isActive(item.path)}" :key="index">
                <keep-alive :include="item.name">
                    <router-link :to="item.path" class="tags-li-title">{{item.title}}</router-link>
                </keep-alive>
                <span class="tags-li-icon" @click="closeTags(index)">
                    <i class="el-icon-close"></i>
                </span>
            </li>
        </ul>
        <div>
            <el-dropdown @command="handleTags">
                <el-button type="primary" class="tag-close-box">标签选项<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                <template #dropdown>
                    <el-dropdown-menu size="small">
                        <el-dropdown-item command="other">关闭其他</el-dropdown-item>
                        <el-dropdown-item command="all">关闭所有</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            tagsList() {
                return this.$store.state.tagsList;
            },
            showTags() {
                return this.tagsList.length > 0;
            }
        },
        methods: {
            isActive(path) {
                return path === this.$route.fullPath;
            },
            closeTags(index) {
                const delItem = this.tagsList[index];
                if (!(this.tagsList.length === 1 && delItem.path === "/home")) {
                    this.$store.commit("delTagsItem", { index });
                    const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
                    if (item) {
                        delItem.path === this.$route.fullPath && this.$router.push(item.path);
                    } else {
                        this.$router.push("/home");
                    }
                } else {
                    this.$message.info("不能关闭此窗口！");
                }
            },
            closeAll() {
                this.$store.commit("closeTagsOther", this.tagsList.filter(item => {
                    return item.path === "/home";
                }));
                this.$router.push("/home");
            },
            closeOther() {
                const curItem = this.tagsList.filter(item => {
                    return item.path === this.$route.fullPath;
                });
                this.$store.commit("closeTagsOther", curItem);
            },
            setTags(route) {
                const isExist = this.tagsList.some(item => {
                    return item.path === route.fullPath;
                });
                if (!isExist) {
                    if (this.tagsList.length >= 8) {
                        this.$store.commit("delTagsItem", { index: 0 });
                    }
                    this.$store.commit("setTagsItem", {
                        name: route.name,
                        title: route.meta.title,
                        path: route.fullPath
                    });
                }
            },
            handleTags(command) {
                command === "other" ? this.closeOther() : this.closeAll();
            }
        },
        watch: {
            $route(newValue) {
                this.setTags(newValue);
            }
        },
        created() {
            this.setTags(this.$route);
        }
    };
</script>


<style>
    .tags {
        display: flex;
        align-items: center;
        padding-right: 10px;
        height: 52px;
        background: #fff;
        box-shadow: 0 0 10px #ddd;
        border-bottom: 1px solid #e9eaec;
    }

    .tags ul {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }

    .tags-li {
        float: left;
        margin: 4px 5px 2px 4px;
        border-radius: 3px;
        font-size: 12px;
        overflow: hidden;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e9eaec;
        background: #fff;
        padding: 0 5px 0 12px;
        vertical-align: middle;
        color: #666;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }

    .tags-li:not(.active):hover {
        background: #f8f8f8;
    }

    .tags-li.active {
        color: #fff;
    }

    .tags-li-title {
        float: left;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 5px;
        color: #666;
    }

    .tags-li.active .tags-li-title {
        color: #fff;
    }

    .tags-close-box {
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        padding-top: 1px;
        text-align: center;
        width: 110px;
        height: 52px;
        margin-right: 20px;
        background: #fff;
        box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
        z-index: 0;
    }

    .tag-close-box {
        /* float: left; */
        /* margin: 4px 5px 2px 4px; */
        border-radius: 3px;
        font-size: 12px;
        /* overflow: hidden; */
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e9eaec;
        background: #fff;
        padding: 0 5px 0 12px;
        vertical-align: middle;
        color: #666;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
</style>
