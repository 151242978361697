import { createRouter, createWebHistory } from "vue-router";
import main from "@/views/main.vue";
import base from "@/utils/baseUtils";

/**
 * 配置路由
 * @type {({redirect: string, path: string}|{path: string, component: (function(): Promise<*>), meta: {title: string}, name: string}|{path: string, component: {components: {vTags: {computed: {tagsList(): *, showTags(): boolean}, watch: {$route(*=): void}, methods: {closeOther(): void, handleTags(*): void, closeAll(): void, setTags(*): void, isActive(*): boolean, closeTags(*): void}, created(): void}, vHeader: {computed: {collapse(): default.computed.$store.state.collapse, account(): (*|string)}, methods: {handleCommand(*): void, collapseChg(): void}, mounted: function(): void, props: {userMessage: any, companyName: any, userAvatar: any, accountList: any, userName: any}}, vSidebar: {data(): {items}, computed: {onRoutes(): *, collapse(): default.computed.$store.state.collapse}}}, data(): {userMessage: number, companyName: string, userAvatar: *, accountList: [{path: string, name: string}, {path: string, name: string}, {path: string, name: string}], userName: string}, computed: {tagsList(): *, collapse(): *}}, children: ({path: string, component: (function(): Promise<*>), meta: {title: string}, name: string}|{path: string, component: (function(): Promise<*>), meta: {title: string}, name: string}|{path: string, component: (function(): Promise<*>), meta: {title: string}, name: string}|{path: string, component: (function(): Promise<*>), meta: {title: string}, name: string}|{path: string, component: (function(): Promise<*>), meta: {title: string}, name: string})[], name: string})[]}
 */
const routes = [
  /**
   * 共通页面设置
   */
  { path: "/", redirect: "/home" },
  {
    path: "/404",
    name: "404",
    meta: { title: "找不到页面" },
    component: () => import("@/views/404.vue"),
  },
  {
    path: "/login",
    name: "accountLogin",
    meta: { title: "登录" },
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
    meta: { title: "找不到页面" },
    hidden: true,
  },

  /**
   * 设置页面路由
   */
  {
    path: "/home",
    name: "homeMain",
    component: main,
    children: [
      {
        path: "/home",
        name: "home",
        meta: { title: "系统首页" },
        component: () => import("@/views/home.vue"),
      },
      {
        path: "/openMemberList",
        name: "openMemberList",
        meta: { title: "开通数据" },
        component: () => import("@/views/user/openMemberList.vue"),
      },
      {
        path: "/preRegistrationManage",
        name: "preRegistrationManage",
        meta: { title: "预注册用户 " },
        component: () => import("@/views/user/preRegistrationManage.vue"),
      },
      {
        path: "/userManage",
        name: "userManage",
        meta: { title: "用户数据" },
        component: () => import("@/views/user/userManage.vue"),
      },
      {
        path: "/orderManage",
        name: "orderManage",
        meta: { title: "订单数据" },
        component: () => import("@/views/user/orderManage.vue"),
      },
      {
        path: "/popularizeManage",
        name: "popularizeManage",
        meta: { title: "推广管理" },
        component: () => import("@/views/operate/popularizeManage.vue"),
      },
      {
        path: "/popularizeData",
        name: "popularizeData",
        meta: { title: "推广数据" },
        component: () => import("@/views/operate/popularizeData.vue"),
      },
      {
        path: "/employeeTotal",
        name: "employeeTotal",
        meta: { title: "推广数据" },
        component: () => import("@/views/operate/employeeTotal.vue"),
      },
      {
        path: "/employeeUser",
        name: "employeeUser",
        meta: { title: "推广用户" },
        component: () => import("@/views/operate/employeeUser.vue"),
      },
      {
        path: "/employeeOrder",
        name: "employeeOrder",
        meta: { title: "推广订单" },
        component: () => import("@/views/operate/employeeOrder.vue"),
      },
      {
        path: "/departmentTotal",
        name: "departmentTotal",
        meta: { title: "部门统计" },
        component: () => import("@/views/operate/departmentTotal.vue"),
      },
      {
        path: "/departmentEmployeeTotal",
        name: "departmentEmployeeTotal",
        meta: { title: "部门员工统计" },
        component: () => import("@/views/operate/departmentEmployeeTotal.vue"),
      },
      {
        path: "/enterpriseManage",
        name: "enterpriseManage",
        meta: { title: "组织构架" },
        component: () => import("@/views/enterprise/enterpriseManage.vue"),
      },
      {
        path: "/roleManage",
        name: "roleManage",
        meta: { title: "角色权限" },
        component: () => import("@/views/enterprise/roleManage.vue"),
      },
      {
        path: "/walletManage",
        name: "walletManage",
        meta: { title: "钱包管理" },
        component: () => import("@/views/enterprise/walletManage.vue"),
      },
      {
        path: "/pwdUpdate",
        name: "pwdUpdate",
        meta: { title: "修改密码" },
        component: () => import("@/views/personal/pwdUpdate.vue"),
      },
      {
        path: "/personalManage",
        name: "personalManage",
        meta: { title: "个人信息" },
        component: () => import("@/views/personal/personalManage.vue"),
      },
      {
        path: "/enterpriseInfo",
        name: "enterpriseInfo",
        meta: { title: "企业信息" },
        component: () => import("@/views/enterprise/enterpriseInfo.vue"),
      },
      {
        path: "/testManage",
        name: "testManage",
        meta: { title: "企业信息" },
        component: () => import("@/views/test.vue"),
      },
    ],
  },
];

/**
 * 创建路由对象
 */
const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

/**
 * 路由判断
 */
const routerSearch = function (toPath, routerUrl) {
  return toPath.search(routerUrl) === 0;
};

/**
 * 设置路由内容
 */
router.beforeEach((to, from, next) => {
  base.setTitle("微猫代理商后台");
  if (!routerSearch(to.path, "/findPwd")) {
    if (!routerSearch(to.path, "/login") && !base.getToken()) {
      next("/login");
    }
  }

  next();
});

export default router;
