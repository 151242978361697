<template>
    <div v-loading="loading" :style="{height: webHeight}" element-loading-text="加载中..."
         element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)">
        <v-header/>
        <v-sidebar/>
        <div class="content-box" :class="{ 'content-collapse': collapse }">
            <v-tag></v-tag>
            <div class="content" style="background-color: #ffffff;">
                <!--页面加载遮罩 @loading="setLoading()"，接收字页面loading方法，需要在子页面中设置emits: ["loading"],this.$emit("loading",true); this.$emit("loading",false);-->
                <router-view v-slot="{ Component }" @loading="setLoading">
                    <keep-alive :include="tagsList">
                        <component :is="Component"/>
                    </keep-alive>
                </router-view>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/layout/header";
import vSidebar from "@/components/layout/menu";
import vTag from "@/components/layout/tag"
import base from "@/utils/baseUtils";

export default {
    emits: ["loading"],
    data() {
        return {
            loading: false,
        }
    },
    components: {
        vHeader,
        vSidebar,
        vTag
    },
    methods: {
        setLoading(loading) {
            this.loading = loading;
        }
    },
    computed: {
        tagsList() {
            return this.$store.state.tagsList.map(item => item.name);
        },
        collapse() {
            return this.$store.state.collapse;
        },
        webHeight() {
            return (base.getClientHeight() + 'px');
        },
    }
};
</script>
