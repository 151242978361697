<template>
    <div class="header">
        <div class="collapse-btn" @click="collapseChg">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="header-left">
            <p style="font-size: 30px;">微猫代理商后台</p>
        </div>
        <div class="header-right">
            <div class="header-user-con">
                <div class="user-avatar"><img :src="userAvatar||userAvatarDefault"  alt="图片"/></div>
                <el-dropdown class="user-name" trigger="click" @command="onHandleCommand">
                    <span class="el-dropdown-link">{{userName}}<i class="el-icon-caret-bottom"></i></span>
                    <template #dropdown>
                        <el-row  style="width: 250px;margin-top: 20px;">
                            <el-col :span="8" style="margin-top: 30px;">
                                <div class="user-avatar2"><img :src="userAvatar"  alt="图片"/></div>
                            </el-col>
                            <el-col :span="16">
                                <ul style="margin-left:10px;line-height:25px;list-style:none;">
                                    <li>姓名：<span style="color: #999797;font-weight: bold;">{{userName}}</span></li>
                                    <li>电话：<span style="color: #999797;font-weight: bold;">{{userPhone}}</span></li>
                                    <li>部门：<span style="color: #999797;font-weight: bold;">{{userDepartment}}</span></li>
                                    <li>职位：<span style="color: #999797;font-weight: bold;">{{userRole}}</span></li>
                                </ul>
                            </el-col>
                        </el-row>
                        <el-dropdown-menu>
                            <el-dropdown-item divided command="updateUserInfo" style="line-height: 30px;height: 30px;">个人信息</el-dropdown-item>
                            <el-dropdown-item command="updateEnterpriseInfo" style="line-height: 30px;height: 30px;">企业信息（<span style="color: #20a0ff;">{{userEnterprise}}</span>）</el-dropdown-item>
                            <el-dropdown-item command="updateUserPwd" style="line-height: 30px;height: 30px;">修改密码</el-dropdown-item>
                            <el-dropdown-item divided command="loginOut" style="line-height: 30px;height: 30px;">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <el-dialog title="修改密码" v-model="updatePwdVisible">
                    <el-form :model="updatePwdForm" status-icon :rules="updatePwdRules" ref="updatePwdForm" label-width="25%" class="demo-ruleForm">
                        <el-form-item label="原始密码" prop="userOldPwd">
                            <el-input type="password" v-model="updatePwdForm.userOldPwd" show-password style="width:60%"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="userNewPwd">
                            <el-input type="password" v-model="updatePwdForm.userNewPwd" show-password style="width:60%"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="UserCheckNewPwd">
                            <el-input type="password" v-model="updatePwdForm.UserCheckNewPwd" show-password style="width:60%"></el-input>
                        </el-form-item>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="onUpdatePwdCancel">取 消</el-button>
                            <el-button type="primary" @click="onUpdatePwd">确 定</el-button>
                        </span>
                    </template>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
import baseUtils from "@/utils/baseUtils";
import { apiCall, apiUrlEnums } from "@/api/requestApi";

export default {
    data() {
        return {
            userName: baseUtils.getLocalStorage("userName"),
            userPhone: baseUtils.getLocalStorage("userPhone"),
            userRole: baseUtils.getLocalStorage("userRole"),
            userDepartment: baseUtils.getLocalStorage("userDepartment"),
            userEnterprise: baseUtils.getLocalStorage("userEnterprise"),
            userAccount: baseUtils.getLocalStorage("userAccount"),
            userAvatar: baseUtils.getLocalStorage("userAvatar"),
            userAvatarDefault: require("@/assets/img/avatar.jpg"),
            updatePwdVisible: false,
            updatePwdForm: {
                userOldPwd: "",
                userNewPwd: "",
                UserCheckNewPwd: ""
            },
            updatePwdRules: {
                userOldPwd: [{ required: true, message: "请输入原始密码", trigger: "blur" }],
                userNewPwd: [{ required: true, message: "请输入新密码", trigger: "blur", }],
                UserCheckNewPwd: [{ required: true, message: "请输入确认密码", trigger: "blur" }],
            },
        }
    },
    computed: {
        collapse() {
            return this.$store.state.collapse;
        },
        messageNum() {
            return 3;
        },
    },
    methods: {
        onMessageHandle(){
            this.$router.push("/login");
        },
        onUpdatePwdCancel() {
            this.$refs["updatePwdForm"].resetFields()
            this.updatePwdVisible = false
        },
        onUpdatePwd() {
            this.$refs["updatePwdForm"].validate((valid) => {
                if (valid) {
                    if(this.updatePwdForm.userNewPwd !== this.updatePwdForm.UserCheckNewPwd){
                        this.$message.error("两次输入的密码不一致");
                    }

                    apiCall(apiUrlEnums.agentUserPwdUpdate, this.updatePwdForm,false).then(res => {
                        if (res.code === 0) {
                            this.$message({
                                showClose: true,
                                type: "success",
                                message: res.msg
                            })
                            this.updatePwdVisible = false
                        } else {
                            this.$message.error(res.msg);
                            return false;
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        onHandleCommand(command) {
            switch (command) {
                case "updateUserInfo":
                    this.$router.push("/personalManage");
                    break;
                case "updateEnterpriseInfo":
                    this.$router.push("/enterpriseInfo");
                    break;
                case "updateUserPwd":
                    this.updatePwdVisible = true;
                    break;
                case "loginOut":
                    apiCall(apiUrlEnums.agentUserLogout, {},false).then(res => {
                        if (res.code === 0) {
                            baseUtils.clearLocalStorage();
                            this.$router.push("/login");
                        } else {
                            baseUtils.clearLocalStorage();
                            this.$router.push("/login");
                        }
                    });

                    break;
            }
        },
        collapseChg() {
            this.$store.commit("collapseChg", !this.collapse);
        },
    },
    created() {
        if(!this.userAvatar){
            this.userAvatar = this.userAvatarDefault;
        }
    }
};
</script>
<style scoped>
    .header {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        font-size: 22px;
        color: #fff;
    }

    .collapse-btn {
        float: left;
        padding: 0 21px;
        cursor: pointer;
        line-height: 70px;
    }

    .header .logo {
        float: left;
        width: 250px;
        line-height: 70px;
    }

    .header-left {
        display: flex;
        height: 70px;
        align-items: center;
        float: left;
        padding-right: 25px;
    }

    .header-right {
        float: right;
        padding-right: 25px;
    }

    .header-user-con {
        display: flex;
        height: 70px;
        align-items: center;
    }

    .btn-bell {
        position: relative;
        top: 5px;
        width: 30px;
        height: 30px;
        padding-left: 10px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
    }

    .btn-bell-badge {
        position: absolute;
        right: 0;
        top: 5px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f56c6c;
        color: #fff;
    }

    .btn-bell .el-icon-chat-line-round {
        color: #fff;
    }

    .btn-bell .el-icon-lx-home {
        color: #fff;
    }

    .btn-bell .el-icon-service {
        color: #fff;
    }

    .btn-bell .el-icon-document-copy {
        color: #fff;
    }

    .user-name {
        margin-left: 20px;
        width: 200px;
    }

    .user-avatar {
        margin-left: 20px;
        margin-right: 5px;
    }

    .user-avatar img {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .user-avatar2 {
        margin-left: 20px;
        margin-right: 5px;
    }

    .user-avatar2 img {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .el-dropdown-link {
        color: #fff;
        cursor: pointer;
    }

    .el-company-link {
        font-size: 10px;
        position: relative;
        top: 5px;
        padding-left: 10px;
        color: #fff;
        cursor: pointer;
    }

    .handle-row {
        display: flex;
        justify-content: end;
        margin-top: 30px;
    }

    .dropdownMenuHeight{
        height: 30px;
        line-height: 30px;
    }
</style>
