<template>
    <div class="sidebar">
        <el-menu router :defaultOpeneds="defaultOpen" :default-active="currMenu" :collapse="collapse" class="sidebar-el-menu" background-color="#324157" text-color="#bfcbd9" active-text-color="#20a0ff">
            <template v-for="oneItem in items">
                <template v-if="oneItem.children && oneItem.children.length">
                    <el-submenu :index="oneItem.menuCode" :key="oneItem.menuCode">
                        <template #title><i :class="oneItem.menuIcon"></i><span>{{ oneItem.menuTitle }}</span></template>
                        <template v-for="twoItem in oneItem.children">
                            <el-submenu v-if="twoItem.children && twoItem.children.length" :index="twoItem.menuCode" :key="twoItem.menuCode">
                                <template #title>{{ twoItem.menuTitle }}</template>
                                <el-menu-item v-for="(threeItem, i) in twoItem.children" :key="i" :index="threeItem.menuCode">
                                    <template #title>{{ threeItem.menuTitle }}</template>
                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :index="twoItem.menuCode" :key="twoItem.menuCode">
                                <template #title>{{ twoItem.menuTitle }}</template>
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="oneItem.menuCode" :key="oneItem.menuCode">
                        <i :class="oneItem.menuIcon"></i>
                        <template #title>{{ oneItem.menuTitle }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
    import base from "@/utils/baseUtils";
    import baseUtils from "@/utils/baseUtils";

    export default {
        data() {
            return {
                defaultOpen: ["home","user","operate","enterprise","personal"],
                currMenuCode: "home",
                specialCodeArray: {
                    "popularizeData":"popularizeManage",
                    "employeeUser":"employeeTotal",
                    "employeeOrder":"employeeTotal",
                    "departmentEmployeeTotal":"departmentTotal",
                },
                items: [{
                    menuCode: "home",
                    menuIcon: "el-icon-s-home",
                    menuId: 0,
                    menuParent: 0,
                    menuTitle: "系统首页",
                    children: [],
                }]
            };
        },
        computed: {
            currMenu() {
                let code = this.$route.path.replace("/", "");
                if(this.specialCodeArray[code]){
                    return this.specialCodeArray[code];
                }else {
                    return code;
                }
            },
            collapse() {
                return this.$store.state.collapse
            }
        },
        methods: {
            menuList() {
                let userMenu = JSON.parse(baseUtils.base64Decode(base.getLocalStorage("userMenu")));
                if (userMenu && userMenu.length > 0) {
                    for (let item of userMenu) {
                        this.items.push(item);
                    }
                }
            },
        },
        mounted: function() {
            this.menuList();
        }
    };
</script>

<style scoped>
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 0;
        overflow-y: scroll;
    }

    .sidebar::-webkit-scrollbar {
        width: 0;
    }

    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 200px;
    }

    .sidebar>ul {
        height: 100%;
    }
</style>
