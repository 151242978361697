<template>
    <router-view />
</template>

<script>
    export default {}
</script>

<style>
    @import "./assets/css/main.css";
    @import "./assets/css/color-dark.css";

    .crumbs {
        color: #ffffff;
    }

    .searchTitle {
        background-color: #DCDFE6;
        padding: 10px;
        background-image: linear-gradient(#DCDFE6, #ffffff);
    }

    .tablePage {
        padding: 10px;
    }

    .searchTool {
        background: #ffffff;
        padding: 0 20px 0 5px;
    }

    .el-pagination {
        text-align: right;
    }

    .toolbar {
        width: 100%;
        box-sizing: border-box;
        background-color: #ffffff;
        height: 80px;
        text-align: center;
        border-bottom: 1px solid #ccc;
        padding: 5px 10px;
    }

    .toolbar .title {
        font-size: 20px;
        line-height: 40px;
    }
</style>
